import React, { ReactElement, useMemo } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'react-bootstrap/Dropdown';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import uuidv4 from 'uuid/v4';

export interface IOptions {
  label: string
  value: string
  disabled?: boolean
}

export interface ISelectToggleProps {
  className?: string
  errors?: string
  onClick?: React.MouseEventHandler
  onBlur?: React.FocusEventHandler
  style?: React.CSSProperties
}

export interface ISelectProps {
  id: string
  name: string
  value?: string|null
  label?: string
  options: IOptions[]
  required?: boolean
  className?: string
  placeholder?: string
  defaultValue?: string
  errors?: string
  onChange?: (event) => void
  onBlur?: React.FocusEventHandler
  disabled?: boolean
  style?: React.CSSProperties
  readOnly?: boolean
  customTooltipIcon?: JSX.Element
  tooltip?: string
}

const questionCircle = <FontAwesomeIcon icon="question-circle" size="1x" />

export const SelectToggle = React.forwardRef<HTMLButtonElement, ISelectToggleProps>(
  ({
    children, onClick, className, errors, ...props
  }, ref) => (
    <button
      type="button"
      className={clsx('form-control', errors && 'invalid', className)}
      onClick={onClick}
      ref={ref}
      {...props}
    >
      {children}
    </button>
  ),
);

function SelectInput({
  name, label, placeholder = '', required, value, defaultValue, customTooltipIcon, tooltip,
  options, errors, onChange, className = '', readOnly, ...props
}: ISelectProps): ReactElement {
  const allOptions = useMemo(
    () => (placeholder ? [{ label: placeholder, value: '' }, ...options] : options),
    [options, placeholder],
  );
  const { id } = props;
  const selectedValue = value || defaultValue || '';
  const selectedLabel = allOptions.find((option) => `${option.value}` === `${selectedValue}`)?.label ?? '';

  const tooltipIcon = customTooltipIcon ?? questionCircle;

  return (
    <div className={clsx('form-group SelectInput', className)}>
      {label && (
        <label htmlFor={id}>
          {label}
          &nbsp;
          {tooltip && (
            <OverlayTrigger
              placement="top"
              overlay={(
                <Tooltip id={`tooltip-${uuidv4()}`} style={{ whiteSpace: 'pre-line' }}>
                  {tooltip}
                </Tooltip>
              )}
            >
              {tooltipIcon}
            </OverlayTrigger>
          )}
          {!tooltip && customTooltipIcon}
        </label>
      )}
      <div style={{ position: 'relative' }}>
        {required ? <span className="required-asterisk">*</span> : null}
      </div>
      <Dropdown show={readOnly ? false : undefined}>
        <Dropdown.Menu popperConfig={
          {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -2],
                },
              }],
          }
        }
        >
          {allOptions.map(((item) => (
            <Dropdown.Item
              key={item.label}
              onClick={() => {
                onChange(item.value)
              }}
              disabled={item.disabled}
            >
              <span className="menu-option">{item.label}</span>
            </Dropdown.Item>
          )))}
        </Dropdown.Menu>
        <Dropdown.Toggle
          id={id}
          errors={errors}
          as={SelectToggle}
          {...props}
        >
          <span className="selected-option">{selectedLabel}</span>
          <FontAwesomeIcon icon="chevron-down" className="SelectInput__arrow" />
        </Dropdown.Toggle>
        <span className="errors">{errors}</span>

      </Dropdown>
    </div>
  );
}

export default SelectInput;
