import { IProfile } from 'reducers/IProfile'
import { IEmailContent, ISMSContent } from './ILibraryContent'

export interface BroadcastFormRecipient {
  FormSessionId: string
  FormId: number
  ProfileId: number
  SendingStatus: string
  Expires: null|string
  ErrorMessage: null|string
}

export interface CreateBroadcastForm{
  FormType: string
  Name: string
  Description: string
  DaysToRespond: string
  SubmissionMessage: string
  AnonymousResponseType: string
}

export interface BroadcastForm {
  FormId: number
  AccountId: number
  FormType: string
  Name: string
  Description: string
  CreatedTimeUTC: string
  CurrentResponse: number
  TotalResponse: number
  IsComplete: boolean
  DaysToRespond: string
  EbfGeneratorId: number
  SubmissionMessage: string
}

interface QuestionOption {
  OptionId: number
  OptionText: string
  WithTextInput: boolean
}

export enum ScaleType {
  LikertScale = 'Likert Scale',
  NetPromoter = 'Net Promoter',
  OpenText = 'Open Text',
  Boolean = 'Boolean',
  MultiSelect = 'Multi-Select',
  Custom = 'Custom',
}

export interface Question {
  QuestionId: number
  QuestionText: string
  ScaleType: ScaleType
  Options: QuestionOption[]|null
}

export interface BroadcastFormResponse {
  Profile: IProfile
  Recipient: BroadcastFormRecipient
  Form: BroadcastForm
  Branding: {
    BrandColor: string|null
    BrandLogoUrl: string|null
    BackgroundColor: string|null
  }
  Questions: Question[]
  Responses: BroadcastFormAnswer[]|null
  CurrentLanguage: string
  AnonymousResponseType: string
}

interface BroadcastFormAnswer {
  QuestionId:number
  ResponseOptionId?: number|number[]|null
  ResponseText?: string|null
}

export interface BroadcastFormRequestBody {
  Response: BroadcastFormAnswer[]|null
  IsAnonymous: boolean
}

export interface BroadcastFormAccountConfiguration {
  SenderDisplayName: string
  SenderEmailAddress: string
  ReplyEmailAddress: string
  BrandColor: string
  BrandLogoContent: string
  ParentAccountId: number
  Enabled: boolean
  BackgroundColor: string
  NotificationRecipients: string[]
  RestrictedBroadcastFormAccess: string[]
}

export interface GetBroadcastFormsParams {
  searchValue?: string
  searchField?: Record<string, any>|string
  sortField?: string
  sortDirection?: string
  page: number
  perpage?: number
  statusType?: string
  includeEnterpriseSurveys?: boolean
}

export interface IBroadcastFormsPayload {
  Data: BroadcastForm[]
  Pagination: {
    PerPage: number
    Page: number
    TotalItems: number
    TotalPages: number
  }
}

export interface BroadcastFormRecipientDetails extends BroadcastFormRecipient{
  FirstName: string
  LastName: string
  HasResponse: boolean
  HasAnonymous: boolean
}

export interface BroadcastFormEvent{
  EventId: number
  EventInstanceId: number
  SendDate: string
  SendTime: string
  SendDateTimeUTC: string
  HasEmailChannel: boolean
  HasSMSChannel: boolean
  EmailRecipients: number
  SMSRecipients: number
}

export interface BroadcastFormResultResponse {
  Recipients: BroadcastFormRecipientDetails[]
  Events: BroadcastFormEvent[]
  Form: BroadcastForm
  HasAnonymous: boolean
}

export interface EnterpriseBroadcastFormGeneratorModel {
  GeneratorId: number
  EnterpriseAccountId: number
  FormType: string
  SurveyName: string
  SendToStaff: boolean
  SendToResidents: boolean
  SendToFamily: boolean
  IsActive: boolean
  Description: string | null
  SendEmail: boolean
  SendSms: boolean
  DaysToRespond: string
  Filters: EnterpriseBroadcastFormGeneratorFilter[]
  OnDeletionHook: boolean
  HookMessageContent: string | null
  SubmissionMessage: string | null
  AnonymousResponseType: string
}

export interface IEbfGeneratorsPayload {
  Data: EnterpriseBroadcastFormGeneratorModel[]
  Pagination: {
    PerPage: number
    Page: number
    TotalItems: number
    TotalPages: number
  }
}

export interface EnterpriseBroadcastFormGeneratorModelEdit {
  FormType: string
  SurveyName: string
  SendToStaff: boolean
  SendToResidents: boolean
  SendToFamily: boolean
  IsActive: boolean
  Description: string | null
  SendEmail: boolean
  SendSms: boolean
  DaysToRespond: string
  Filters: EnterpriseBroadcastFormGeneratorFilter[]
  OnDeletionHook: boolean
  HookMessageContent: string | null
  SubmissionMessage: string | null
  AnonymousResponseType: string
}

export interface AddGeneratorRequest {
  formType: string
  model: EnterpriseBroadcastFormGeneratorModelEdit
}

export interface BroadcastFormResendResponse{
  FormId: number
  AccountId: number
  FormType: string
  Name: string
  Description: string
  Location: string
  GroupIds: number[]
  ProfileIds: string[]
  EmailContent?: IEmailContent
  SMSContent?: ISMSContent
  DaysToRespond: string
  SubmissionMessage: string
  AnonymousResponseType: string
}

export interface GetEbfGeneratorParams {
  searchValue?: string
  searchField?: Record<string, any>|string
  sortField?: string
  sortDirection?: string
  page: number
  perpage?: number
  isActive?: boolean
}

export interface EnterpriseFormOneTimeEventModel {
  OneTimeEventId: number
  EnterpriseAccountId: number
  GeneratorId: number
  SendDate: string
  SendTimeLocal: string
  SendDateTime: string
  Message: string
  SendState: string
}

export interface EnterpriseFormOneTimeEventModelEdit {
  SendDate: string
  SendTimeLocal: string
  Message: string
}

export interface AddOneTimeEventRequest {
  generatorId: number
  model: EnterpriseFormOneTimeEventModelEdit
}

export interface UpdateOneTimeEventRequest {
  oneTimeEventId: number
  model: EnterpriseFormOneTimeEventModelEdit
}

export interface DeleteOneTimeEventRequest {
  oneTimeEventId: number
}

export interface EnterpriseFormTriggeredEventModel {
  TriggeredEventId: number
  EnterpriseAccountId: number
  GeneratorId: number
  Message: string
  SendTimeLocal: string
  TriggerField: string
  TriggerDayOffset: number
  LastSendDate: string | null
}

export interface EnterpriseFormTriggeredEventModelEdit {
  Message: string
  SendTimeLocal: string
  TriggerField: string
  TriggerDayOffset: number
}

export interface BroadcastFormQuestion {
  QuestionId: number
  QuestionText: string
  ScaleType: string
}

export interface EbfAccountEventsConfig {
  WarningMessages: string[]
  ComplianceDate1?: string
  ComplianceDate2?: string
  ComplianceDate3?: string
  ComplianceDate4?: string
  ComplianceDate5?: string
  ComplianceDate6?: string
  ComplianceDate7?: string
  ComplianceDate8?: string
  LifeDate1?: string
  LifeDate2?: string
  ConfiguredEventDates?: { [key: string]: string }
}

export interface EnterpriseBroadcastFormGeneratorFilter {
  FilterId?: number
  EnterpriseAccountId: number
  GeneratorId: number
  FilterType: string
  FilterField: string
  FilterValue: string
  FilterStartDate: string
  FilterEndDate: string
  Id: string
}
