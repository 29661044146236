import React, {
  ReactElement, useCallback, useState, useEffect,
} from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { format } from 'date-fns/esm';
import { useHistory, useParams } from 'react-router-dom';
import { EventTemplateForm as EventStepper } from 'components/EventTemplateForm/EventTemplateForm';
import { EventSchedulerForm as EventScheduler } from 'components/EventScheduler';
import {
  createEvent, eventRequestFinished, eventRequestStarted, validateEventContent,
} from 'reducers/Events';
import { getUserState } from 'reducers/UserInfo';
import {
  IEvent, EventType, ScheduleMode, EventTemplateMode,
  VoiceMessageTypeEnum,
} from 'types/IEvent';
import * as BroadcastFormApi from 'api/BroadcastFormAPI';
import useSWRMutation from 'swr/mutation';
import useSWR from 'swr';
import { LanguageNames } from 'lib/CommonSelect';
import { Spinner } from 'react-bootstrap';

export default function BroadcastFormResentPage(): ReactElement {
  const { formId } = useParams<{ formId?: string }>();
  const [showStepper, setShowStepper] = useState(true);
  const [data, setData] = useState<IEvent|null>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfo = useSelector(getUserState);
  const swrKey = ['getbroadcastformresentbyid', formId];
  const { isLoading, isValidating } = useSWR(
    swrKey,
    () => BroadcastFormApi.getFormResendById(formId),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      onSuccess: (broadcastformResent) => {
        if (!broadcastformResent) {
          return;
        }
        const broadcastformData : IEvent = {
          Name: broadcastformResent?.Name,
          Description: broadcastformResent?.Description,
          BroadcastFormId: broadcastformResent?.FormId,
          FormType: broadcastformResent?.FormType,
          AnonymousResponseType: broadcastformResent?.AnonymousResponseType,
          DaysToRespond: broadcastformResent?.DaysToRespond,
          SubmissionMessage: broadcastformResent?.SubmissionMessage,
          Location: broadcastformResent?.Location,
          EmailContent: broadcastformResent?.EmailContent,
          SMSContent: broadcastformResent?.SMSContent,
          VoiceContent: null,
          GroupIds: broadcastformResent?.GroupIds,
          ProfileIds: broadcastformResent?.ProfileIds,
          ID: undefined,
          TemplateID: 0,
          EventType: EventType.URLLINKSURVEY,
          StartDate: format(new Date(), 'yyyy-MM-dd'),
          SendTime: moment().tz(userInfo.accountTimezone).add(5, 'minutes').format('HH:mm'),
          SendMode: ScheduleMode.Once,
          RRule: '',
          ExpirationTime: null,
          IsBroadcastForm: true,
          IsEmergency: false,
          VoiceMessageType: VoiceMessageTypeEnum.Both,
          Status: '',
          StopDateLocal: null,
          SourceLanguage: LanguageNames.English,
          Category: 'Activity',
          IsSendNow: false,
        }
        setData(broadcastformData);
      },
      onError: (error, key) => {
        toast.error('Can\'t resend the survey because there was no event scheduled or sent.');
        history.push('/feedback360')
      },
    });

  useEffect(() => {
    setData((oldData) => {
      if (!oldData) {
        return null;
      }
      return {
        ...oldData,
        SendTime: moment().tz(userInfo.accountTimezone).add(5, 'minutes').format('HH:mm'),
      }
    });
  }, [userInfo.accountTimezone, isLoading, isValidating]);
  const saveData = useCallback((newData: Partial<IEvent>) => {
    setData((oldData) => ({
      ...oldData,
      ...newData,
    }));
  }, [setData, isLoading, isValidating]);

  const onSuccessCb = (stepperData: Partial<IEvent>) => {
    setShowStepper(false);
    saveData(stepperData);
  }

  const handleSubmitStepper = useCallback((formData) => {
    const stepperData: Partial<IEvent> = {
      ...formData,
    };
    const surveyEvent: IEvent = {
      ...data,
      ...formData,
    }
    dispatch(validateEventContent([surveyEvent], 'urllinksurvey', () => {
      onSuccessCb(stepperData);
    }, (err) => {
    }));
  }, [saveData]);

  const addRecipientsByEventId = async (url, { arg }: { arg: { formId: number, eventId: number } }) => {
    dispatch(eventRequestStarted());
    await BroadcastFormApi.addRecipientsByEventId(arg.formId, arg.eventId);
  }
  const { trigger: addRecipients } = useSWRMutation('addrecipientsbyeventId', addRecipientsByEventId, {
    revalidate: true,
    populateCache: false,
    onSuccess: () => {
      dispatch(eventRequestFinished());
      toast.success('Survey successfully created');
      history.push('/feedback360');
    },
  });

  const onSuccessCbf = (surveyEvent: IEvent, event: IEvent) => {
    addRecipients({ formId: surveyEvent.BroadcastFormId, eventId: event.ID });
  }

  const handleSubmitScheduler = useCallback((formData) => {
    const surveyEvent: IEvent = {
      ...data,
      ...formData,
    }
    dispatch(createEvent(surveyEvent, (event) => {
      onSuccessCbf(surveyEvent, event);
    }));
  }, [data, dispatch, history]);

  return (
    <div className="TemplatesEdit">
      <Helmet>
        <title>Resend Feedback360 Survey</title>
      </Helmet>
      <div className="header-bar">
        <h1>Resend Feedback360 Survey</h1>
      </div>
      {(isLoading || data == null) && (
        <div
          className="loading-text d-flex align-items-center justify-content-center"
          role="status"
        >
          <Spinner
            animation="border"
            as="span"
            className="mr-2"
            style={{ width: '1.66em', height: '1.66em' }}
          />
          <span style={{ fontSize: '1.25em' }}>
            Loading...
          </span>
        </div>
      )}
      {!isLoading && data !== null && (showStepper ? (
        <EventStepper
          isNew={false}
          data={data}
          eventTemplateMode={EventTemplateMode.Event}
          allowPreview={false}
          onSubmit={handleSubmitStepper}
          onCancel={() => history.push('/feedback360')}
        />
      ) : (
        <EventScheduler
          data={data}
          onSubmit={handleSubmitScheduler}
        />
      ))}
    </div>
  );
}
