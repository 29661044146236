import React, {
  ReactElement,
  useMemo,
} from 'react';
import { Link, useParams } from 'react-router-dom';
import * as BroadcastFormApi from 'api/BroadcastFormAPI';
import Table from 'components/Table';
import useSWR from 'swr';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectAccountTz } from 'reducers/UserInfo';
import { CellProps } from 'react-table';
import { BroadcastFormEvent, BroadcastFormRecipientDetails } from 'types/BroadcastForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import uuidv4 from 'uuid/v4';

const formatDateSent = (acctTz: string, date: string) => {
  const localTime = moment
    .utc(date, 'YYYY-MM-DDTHH:mm:ss.SSS')
    .tz(acctTz);

  return localTime.format('MMM D, YYYY h:mm a');
}
const formatDateSentCell = (acctTz: string) => (props: CellProps<Record<string, unknown>>) => {
  const { cell: { value } } = props;
  return formatDateSent(acctTz, value);
}

const ChannelCell: React.FC<CellProps<BroadcastFormEvent>> = (props: CellProps<BroadcastFormEvent>) => {
  const {
    row: { original },
  } = props;
  return (
    <span>
      {original?.HasSMSChannel && (
        <OverlayTrigger
          placement="top"
          overlay={(
            <Tooltip id={`tooltip-sms-${uuidv4()}`}>
              SMS
            </Tooltip>
          )}
        >
          <FontAwesomeIcon icon={'comment' as IconName} />
        </OverlayTrigger>
      )}
      {' '}
      {original?.HasEmailChannel && (
        <OverlayTrigger
          placement="top"
          overlay={(
            <Tooltip id={`tooltip-email-${uuidv4()}`}>
              Email
            </Tooltip>
          )}
        >
          <FontAwesomeIcon icon={'envelope-square' as IconName} />
        </OverlayTrigger>
      )}
    </span>
  );
}

export default function BroadcastFormResults():ReactElement {
  const { formId } = useParams<{ formId?: string }>();
  const accountTz = useSelector(selectAccountTz);
  const renderRecipientResponse = (hasResponse: boolean, hasAnonymous: boolean) => {
    if (hasAnonymous) {
      return 'Hidden';
    }
    return hasResponse ? 'Yes' : 'No'
  }
  const recipientColumns = useMemo(() => [
    { Header: 'First Name', accessor: 'FirstName' },
    { Header: 'Last Name', accessor: 'LastName' },
    { Header: 'Sending Status', accessor: 'SendingStatus' },
    {
      Header: 'Has Responded',
      accessor: 'HasResponse',
      Cell: (props: CellProps<BroadcastFormRecipientDetails>): ReactElement => {
        const {
          row: { original },
        } = props;
        return (
          <span>
            {renderRecipientResponse(original?.HasResponse, original?.HasAnonymous)}
          </span>
        );
      },
    },
    { Header: 'Error Message', accessor: 'ErrorMessage' },
  ], []);

  const eventColumns = useMemo(() => [
    { Header: 'Event ID', accessor: 'EventId' },
    { Header: 'Event Instance Id', accessor: 'EventInstanceId' },
    { Header: 'Send Date and Time', accessor: 'SendDateTimeUTC', Cell: formatDateSentCell(accountTz) },
    {
      Header: 'Channels',
      accessor: 'Channels',
      Cell: ChannelCell,
    },
    { Header: 'Email Recipients', accessor: 'EmailRecipients' },
    { Header: 'SMS Recipients', accessor: 'SMSRecipients' },
  ], []);

  const swrKey = ['getbroadcastformbyid', formId];
  const { data: formDetails, isLoading } = useSWR(
    swrKey,
    () => BroadcastFormApi.getFormResultById(formId),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    });
  if (!formDetails || isLoading) {
    return <div />;
  }

  const rowActions = (entityId: string, entity: BroadcastFormEvent) => (
    <Link
      to={`/events/${entityId}/results/${moment(entity.SendDate).format('YYYY-MM-DD')}`}
    >
      View Event Report
    </Link>
  )

  return (
    <div>
      <h3>{formDetails?.Form.Name}</h3>
      <p>{formDetails?.Form.Description}</p>
      <p>{`${formatDateSent(accountTz, formDetails?.Form.CreatedTimeUTC)}`}</p>
      {formDetails?.HasAnonymous && (<p style={{ color: ' #dc3545' }}>Response status is hidden as this survey contains recipients that have responded anonymously</p>) }
      <Table
        columns={recipientColumns}
        data={formDetails?.Recipients || []}
        enableCheck={false}
      />
      <h4 className="pt-4 pb-4" style={{ marginTop: 10 }}>Sending Events</h4>
      <Table
        columns={eventColumns}
        data={formDetails?.Events || []}
        enableCheck={false}
        rowIDAccessor="EventId"
        rowActionItems={rowActions}
      />
    </div>
  );
}
